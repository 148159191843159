import * as u from 'JS/utils';
import {tns} from 'tiny-slider/src/tiny-slider'

u.domReady(function() {
  const $features_carousel = document.querySelector('.subfeatures-items');

  if ($features_carousel) {
    const $features_items = $features_carousel.querySelectorAll('.subfeature');

    if ($features_items.length > 2) {
      const slider = tns({
        container: $features_carousel,
        items: 1,
        gutter: 24,
        controls: false,
        responsive: {
          768: {
            items: 2,
            gutter: 48
          }
        }
      });

      window.addEventListener('resize', function(){
        slider.refresh();
      });

      document.querySelector('#next').addEventListener('click', function(){
        slider.goTo('next');
      })

      document.querySelector('#previous').addEventListener('click', function(){
        slider.goTo('prev');
      })
    }
  }

  const $about_carousel = document.querySelector('.quote-carousel');

  if ($about_carousel) {
    const slider = tns({
      container: $about_carousel,
      items: 1,
      controls: false,
      startIndex: 3,
      center: true,
      rewind: true,
      responsive: {
        768: {
          // items: 3,
          // edgePadding: 50
        },
        1024: {
          // edgePadding: 125
        }
      }
    })

    changeClasses();
    getQuoteData()

    slider.events.on('indexChanged', function(e) {
      dumpClasses();
      changeClasses();
      getQuoteData()
    })

    document.querySelector('#next').addEventListener('click', function(){
      slider.goTo('next');
    })

    document.querySelector('#previous').addEventListener('click', function(){
      slider.goTo('prev');
    })

    function dumpClasses() {
      const $last_active = $about_carousel.querySelector('.previous');

      if($last_active) {
        $last_active.classList.remove('previous');
      } else if ($about_carousel.querySelector('.previous-last')) {
        $about_carousel.querySelector('.previous-last').classList.remove('previous-last');
      }
    }

    function changeClasses() {
      const $slide = $about_carousel.querySelector('.tns-slide-active');

      if ($slide.previousElementSibling) {

        if ($slide.previousElementSibling.previousElementSibling) {
          $slide.previousElementSibling.previousElementSibling.classList.add('previous');
        } else {
          $slide.previousElementSibling.classList.add('previous-last');
        }
      }
    }

    function getQuoteData() {
      const $quoteElement = document.querySelector('#quote-output');
      $quoteElement.classList.add('hidden');
      const $quote = $about_carousel.querySelector('.tns-slide-active');

      // Timeout for effect of animation, fades out for 300ms when 'hidden' class is added
      setTimeout(function(){
        $quoteElement.querySelector('.quote-quote').innerText = $quote.getAttribute('data-quote');
        // $quoteElement.querySelector('.quote-author').innerText = $quote.getAttribute('data-name');
        // $quoteElement.querySelector('.quote-position').innerText = $quote.getAttribute('data-position');
        // $quoteElement.querySelector('.quote-company').innerText = $quote.getAttribute('data-company');
        $quoteElement.classList.remove('hidden');
      }, 300)
    }
  }

  const $carousel_3_items = document.querySelectorAll('.three_item_carousel');
  let $sliders = [];

  if ($carousel_3_items) {

    Array.prototype.forEach.call($carousel_3_items, function(carousel, i) {

      $sliders[i] = tns({
        container: carousel,
        items: 1,
        center: true,
        rewind: true,
        // loop: true,
        // startIndex: 1,
        controls: false,
        nav: false,
        gutter: 18
      })

      changeHomeClasses();

      document.querySelector('#next').addEventListener('click', function(){
        $sliders[i].goTo('next');
      })

      document.querySelector('#previous').addEventListener('click', function(){
        $sliders[i].goTo('prev');
      })

      $sliders[i].events.on('indexChanged', function(e) {
        dumpHomeClasses();
        changeHomeClasses();
      })

      function dumpHomeClasses() {
        const $last_slides = carousel.querySelectorAll('.listing_block');
        Array.prototype.forEach.call($last_slides, function(last, i) {
          last.classList.remove('right');
          last.classList.remove('left');
        })
      }

      function changeHomeClasses() {
        const $slide = carousel.querySelector('.tns-slide-active');

        if ($slide.previousElementSibling) {
          $slide.previousElementSibling.classList.add('left');
        }

        if ($slide.nextElementSibling) {
          $slide.nextElementSibling.classList.add('right');
        }
      }
    })
  }
})
