import * as u from 'JS/utils'

u.domReady(function() {
  const el = document.getElementById('cookies-bar')

  if (el === null) return;

  const close = el.querySelector('button')
  const moreInfo = el.querySelector('a')

  let accepted = localStorage.getItem('royston_cookie_accepted') || false

  if (!accepted) {
    el.classList.remove('closed')
  }

  el.addEventListener('click', function(e) {
    if (e.target != moreInfo) {
      el.classList.add('closed')
      localStorage.setItem('royston_cookie_accepted', true)
    }
  })
})